<template>
  <div class="crypto-container">
    <b-image
      v-if="url !== ''"
      class="crypto-logo"
      :src="url"
      :alt="name"
    ></b-image>
    <b-image
      v-else
      class="crypto-logo"
      :src="require(`@/assets/images/crypto/${this.ticker.toLowerCase()}.svg`)"
      :alt="name"
    ></b-image>
  </div>
</template>

<script>
export default {
  name: 'CryptoLogo',
  props: {
    name: {
      type: String,
      required: true,
    },
    ticker: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '2.5rem',
    },
    minWidth: {
      type: String,
      default: '2.5rem',
    },
    url: {
      type: String,
      default: '',
    },
  }
}
</script>

<style lang="scss">
.crypto-order-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crypto-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .crypto-logo {
    margin-right: 0.5rem;
    width: v-bind(width);
    min-width: v-bind(minWidth);
  }
}
</style>
