<template>
  <div>
    <div class="section" :class="{ 'px-5': isMobile }">
      <div class="columns pt-2">
        <div class="column is-8" :class="{ 'px-0': isMobile }">
          <section v-if="loading">
            <b-skeleton
              :active="loading"
              :count="perPage"
              :rounded="true"
              height="45"
            ></b-skeleton>
          </section>
          <b-table
            v-else
            :data="currenciesWithPrices"
            :hoverable="false"
            :mobile-cards="false"
            :paginated="isDesktop || isTablet"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-rounded="true"
            pagination-order="is-centered"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            class="currencies-table"
            @cellclick="onCellClick"
          >
            <!-- currency -->
            <b-table-column
              :label="$t('currency.currency')"
              :td-attrs="columnTdAttrs"
              v-slot="props"
            >
              <div class="is-flex">
                <crypto-logo
                  :ticker="props.row.ticker"
                  :name="props.row.name"
                  :url="props.row.logoURL"
                  :width="'1.5rem'"
                  :minWidth="'1.5rem'"
                >
                </crypto-logo>
                <div>
                  <div class="is-flex has-text-weight-bold">
                    {{ props.row.name }}
                  </div>
                  <div class="is-flex">
                    <span class="is-size-8">{{ props.row.ticker }}</span>
                    <div class="tags" v-if="hasValidTags(props.row.tags)">
                      <b-tag
                        class="ml-2"
                        v-for="(tag, index) in props.row.tags"
                        :key="index"
                        type="is-success is-light"
                      >
                        {{ tag }}
                      </b-tag>
                    </div>
                  </div>
                </div>
              </div>
            </b-table-column>
            <!-- price & trend -->
            <b-table-column
              :label="`${$t('fields.price')} ${ $t('currency.BGN') }`"
              :td-attrs="columnTdAttrs"
              numeric
              v-slot="props"
            >
              <div>
                {{ getRoundedNumber(props.row.price) }}
              </div>
              <div>
                <span
                  class="is-size-8"
                  :class="props.row.change > 0 ? 'has-text-success' : 'has-text-danger'"
                >
                  {{ getRoundedTwoDecimalsWithSign(props.row.change) }}%
                </span>
              </div>
            </b-table-column>
            <!-- 7 day chart -->
            <b-table-column
              v-if="isDesktop"
              :label="$t('currency.oneWeekChart')"
              numeric
              v-slot="props"
            >
              <img
                v-if="props.row.chartURL"
                :src="props.row.chartURL"
                decoding="async"
                loading="lazy"
                style="height: 50px; width: 200px;"
              />
            </b-table-column>
            <!-- market cap -->
            <b-table-column
              :label="$t('currency.marketCap')"
              :td-attrs="columnTdAttrs"
              width="150px"
              numeric
              v-slot="props"
            >
              <span>{{ friendlyBigNumber(props.row.marketCap) }}</span>
            </b-table-column>
            <!-- sell/buy -->
            <b-table-column
              v-if="isDesktop"
              label=""
              width="100px"
              v-slot="props"
              :td-attrs="columnTdAttrs"
            >
              <b-button
                type="is-primary"
                :label="$t('buttons.buyAndSell')"
                @click="cryptoDetails(props.row.id, 'buy')"
              />
            </b-table-column>
            <b-table-column
              v-else
              label=""
              v-slot="props"
              :td-attrs="columnTdAttrs"
            >
              <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
                <template #trigger>
                  <b-icon icon="ellipsis-vertical has-text-white"></b-icon>
                </template>
                <b-dropdown-item aria-role="listitem" @click="cryptoDetails(props.row.id, 'buy')">
                  {{ $t('buttons.buy') }}
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="cryptoDetails(props.row.id, 'sell')">
                  {{ $t('buttons.sell') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </b-table>
        </div>
        <div v-if="isDesktop" class="column is-offset-1">
          <div class="mobile-width py-5">
            <div class="is-flex is-justify-content-center pb-5">
              <b-image
                style="width: 65%"
                :src="require('@/assets/images/phone.gif')"
                alt="CryptoDesk"
              />
            </div>
            <div class="is-flex is-justify-content-center">
              <store-links />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StoreLinks from '@/components/ui/StoreLinks.vue'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import numbersMixin from '@/mixins/numbers-mixin'
import CryptoLogo from '@/components/common/CryptoLogo'

export default {
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      description: this.$t('meta.description'),
      meta: [
        { property: 'og-type', content: 'website' },
        { property: 'og-site-name', content: 'CryptoDesk' },
        { property: 'og-image', content: 'imageurl' },
        { property: 'twitter:title', content: this.$t('meta.title') },
        { property: 'twitter:site', content: 'CryptoDesk' },
        {
          property: 'twitter:image',
          content: `https://cryptodesk.bg${require('@/assets/images/logo_big.png')}`,
        },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    }
  },
  name: 'Home',
  components: {
    StoreLinks,
    CryptoLogo,
  },
  mixins: [numbersMixin, VueBreakpointMixin],
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'coin/currencies',
      currenciesWithPrices: 'coin/currenciesWithPrices',
    }),
  },
  async created() {
    try {
      await this.fetchCurrencies()
      const pricesParams = {
        vs_currencies: 'eur',
        include_24hr_change: true,
        include_market_cap: true,
        ids: this.currencies.map(x => x.apiName).join(','),
      }
      await this.fetchPrices(pricesParams)
      this.loading = false
    } catch (error) {
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t('errors.fetchError'),
        position: 'is-bottom',
        type: 'is-danger',
      })
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      fetchCurrencies: 'coin/fetchCurrencies',
      fetchPrices: 'coin/fetchPrices',
    }),
    columnTdAttrs(_row, _column) {
      return {
        style: { 'vertical-align': 'middle' },
      }
    },
    hasValidTags(tagsArr) {
      if (!tagsArr || !tagsArr.length) return null
      const tags = tagsArr.join(' ').trim()
      return tags
    },
    cryptoDetails(cryptoId, type) {
      this.$router.push({ name: type, params: { id: cryptoId } })
    },
    onCellClick(row, _column, _rowIndex, columnIndex) {
      if (this.isDesktop || columnIndex !== 0) return
      this.$router.push({ name: 'buy', params: { id: row.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-image-wrapper.image {
  display: inline-block;
  height: 100%;
  align-self: center;
  /deep/ .crypto-logo {
    vertical-align: middle;
  }
}

.tag.is-success.is-light {
  height: 1.4em !important;
  font-size: $size-8;
}

.trade-btns ::v-deep .button {
  min-width: 80px;
}
.currencies-table {
  /deep/ table.table {
    background-color: $dark;
    color: $light;
  }
  /deep/ .table thead th {
    color: $light;
    padding: 0.5em 0.5em;
    font-size: $size-7;
    border-bottom: 1px solid $light;
  }
  /deep/ .table td {
    padding: 0.5em 0.5em;
  }
  /deep/ .pagination-link {
    border-color: $light;
    color: $light;
  }
  /deep/ .pagination-link.is-current {
    background-color: $primary;
    border-color: $primary;
    color: $dark;
  }
  /deep/ .pagination-link[disabled] {
    color: $dark !important;
  }
  /deep/ .table.is-hoverable tbody tr:not(.is-selected):hover {
    background-color: $blue-hoverable;
  }
  /deep/ .top.level {
    justify-content: center;
  }
}
.mobile-width ::v-deep .container {
  width: 90%;
}
</style>
