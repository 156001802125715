<template>
  <div>
    <a
      class="store m-2"
      href="https://play.google.com/store/apps/details?id=com.blockchaintech.cryptodesk&hl=en&gl=US"
      target="_blank"
      rel="noopener noreferrer"
    >
      <b-image
        class="store-image"
        :src="require(`@/assets/images/googleplay.svg`)"
      />
    </a>
    <a
      class="store m-2"
      href="https://apps.apple.com/bg/app/cryptodesk/id6445974552"
      target="_blank"
      rel="noopener noreferrer"
    >
      <b-image
        class="store-image"
        :src="require(`@/assets/images/appstore.svg`)"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'StoreLinks',
}
</script>

<style lang="scss" scoped>
.store {
  display: inline-block;

  .store-image {
    width: 128px;
  }
}
</style>
