export default {
  data () {
    return {
      numberFormatter2: new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      numberFormatter4: new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      }),
      numberFormatter6: new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      })
    }
  },
  methods: {
    getRoundedNumber (number) {
      if (!number) {
        return ''
      }

      if (number < 0.0001) {
        return number.toString().indexOf('.') === -1 ? number : this.numberFormatter6.format(number)
      }

      if (number < 0.001) {
        return number.toString().indexOf('.') === -1 ? number : this.numberFormatter4.format(number)
      }

      return number.toString().indexOf('.') === -1 ? number : this.numberFormatter2.format(number)
    },
    getRoundedTwoDecimals (number) {
      if (!number) {
        return ''
      }

      return number.toString().indexOf('.') === -1 ? number : this.numberFormatter2.format(number)
    },
    getRoundedTwoDecimalsWithSign (number) {
      const num = this.getRoundedTwoDecimals(number)
      if (num > 0) {
        return '+' + num
      }

      return num
    },
    getFloatWithTwoDecimals (number) {
      if (!number) {
        return ''
      }

      return parseFloat(number).toFixed(2)
    },
    friendlyBigNumber(number) {
      if (number >= 1e9) {
        return (number / 1e9).toFixed(1) + 'B'
      } else if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + 'M'
      } else {
        return number.toString()
      }
    }
  }
}
